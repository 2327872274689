import React from "react";
import {
  Page,
  Button,
  Card,
  Layout,
  Stack,
  TextField,
  Toast,
  Frame,
  Spinner,
  SettingToggle,
  Heading,
  Subheading,
  TextStyle,
  Select,
  Checkbox,
  EmptyState,
  Modal,
  TopBar,
  TextContainer,
  Link,
  Icon,
  ProgressBar,
  Badge,
  Navigation,
  DataTable,
  Pagination,
  Banner,
  Label
} from "@shopify/polaris";
import createApp from "@shopify/app-bridge";
import { Context } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import {
  HomeMajorMonotone,
  CirclePlusOutlineMinor,
  LinkMinor,
  QuestionMarkMajorMonotone,
  CircleInformationMajorMonotone,
  BlogMajorMonotone,
  AnalyticsMajorMonotone,
  SettingsMajorMonotone,
  ExchangeMajorMonotone,
  ImageMajorMonotone
} from "@shopify/polaris-icons";
import { format } from "date-fns";
import firebase from "firebase/app";
import "firebase/storage";

import RssIcon from "./assets/images/rss.svg";
import ShopifyIcon from "./assets/images/shopify.svg";
import WixIcon from "./assets/images/wix.svg";
import SquarespaceIcon from "./assets/images/squarespace.svg";
import WordpressIcon from "./assets/images/wordpress.svg";
import MediumIcon from "./assets/images/medium.svg";
import TumblrIcon from "./assets/images/tumblr.svg";
import BloggerIcon from "./assets/images/blogger.svg";
import WebflowIcon from "./assets/images/webflow.svg";

const sourceIcons = {
  all: RssIcon,
  shopify: ShopifyIcon,
  squarespace: SquarespaceIcon,
  wix: WixIcon,
  wordpress: WordpressIcon,
  medium: MediumIcon,
  tumblr: TumblrIcon,
  blogger: BloggerIcon,
  webflow: WebflowIcon
};

const lastWeek = new Date();
lastWeek.setDate(lastWeek.getDate() - 7);

class IndexV3 extends React.Component {
  static contextType = Context;

  state = {
    medium_profile: "",
    medium_handle: "",
    domain: "",
    api: "https://medium-feed.web.app",
    showToast: false,
    fields: {
      medium_profile: {
        validate: value => {
          if (!value) return "This is a required field";
          const mediumRegex = /^http(s|):\/\/(www.|)medium.com\/(@|)([a-zA-Z0-9-_.+]+)$/;
          const customMediumRegex = /^http(s|):\/\/([a-zA-Z0-9-]+)\.medium\.com\/?$/;
          if (!mediumRegex.test(value) && !customMediumRegex.test(value))
            return "This is not a valid Medium URL";
          return null;
        },
        error: null
      }
    },
    settingsLoading: false,
    syncLoading: false,
    pullLoading: false,
    chargeLoading: false,
    cancelLoading: false,
    importLoading: false,
    sourceLoading: false,
    pageLoading: false,
    cancelModal: false,
    autosync: false,
    last_sync: null,
    initializing: true,
    redirect: false,
    toastContent: "",
    toastError: false,
    hasError: false,
    showPayment: false,
    last_sources: [],
    blogs: [],
    blog: null,
    blog_id: null,
    sources: [],
    articles: [],
    page: "dashboard",
    filter: "all",
    yearly: false,
    active: false,
    verified: null,
    posts: [],
    post_ids: [],
    app: null,
    test: false,
    medium_import: null,
    newsletterModal: false,
    currentReaders: 0,
    newsletterLoading: false,
    newsletter: false,
    selectedSource: null,
    sourceFilter: "",
    articlePage: 1,
    articlesPageInfo: null,
    confirmDeleteSource: null,
    confirmDeleteArticle: null,
    articleLoading: false,
    mediumImportRequest: null,
    analyticsRange: [lastWeek, new Date()],
    analytics: null,
    analyticsPost: null,
    recentArticles: [],
    monthlyPosts: 0,
    sourcesCount: 0,
    sourceSetup: {
      medium:
        "To setup a Medium source, simply indicate your Medium profile URL (example: https://medium.com/@USERNAME) or Medium publication page URL (example: https://medium.com/PUBLICATION_NAME).",
      wordpress: (
        <Link
          url="https://wordpress.com/support/settings/reading-settings/"
          external
        >
          Follow the guide provided by WordPress to setup your RSS feed.
        </Link>
      ),
      wix: (
        <Link
          url="https://support.wix.com/en/article/connecting-your-blog-to-an-rss-feed"
          external
        >
          Follow the guide provided by Wix to setup your RSS feed.
        </Link>
      ),
      webflow: (
        <Link
          url="https://university.webflow.com/article/set-up-an-rss-feed-for-your-collections"
          external
        >
          Follow the guide provided by Webflow to setup your RSS feed.
        </Link>
      ),
      squarespace: (
        <Link
          url="https://support.squarespace.com/hc/en-us/articles/206543187-Finding-your-RSS-feed-URL"
          external
        >
          Follow the guide provided by Squarespace to setup your RSS feed.
        </Link>
      ),
      shopify: (
        <Link
          url="https://help.shopify.com/en/manual/online-store/legacy/blogs/publishing-blogs#invite-customers-to-subscribe-to-your-blog"
          external
        >
          Follow the guide provided by Shopify to setup your RSS feed.
        </Link>
      ),
      tumblr:
        "To get your Tumblr RSS URL, simply add /rss at the end of your blog URL. (i.e. https://my-blog.tumblr.com/rss).",
      blogger:
        "To get your Blogger RSS URL, simply add /feeds/posts/default after your blog URL. (i.e. https://blog.blogspot.com/feeds/posts/default)."
    }
  };

  constructor(props) {
    super(props);
    this.uploader = React.createRef();
  }

  renderPlans() {
    const { chargeLoading, active, showPayment } = this.state;

    return (
      <Layout>
        <Layout.Section oneHalf>
          <Card
            title={
              <Stack spacing="tight" alignment="center">
                <Heading>Free Plan</Heading>
                {!active && <Badge status="info">Current Plan</Badge>}
              </Stack>
            }
            secondaryFooterActions={
              showPayment
                ? [
                    {
                      content: "Continue",
                      onAction: () => this.setState({ showPayment: false })
                    }
                  ]
                : []
            }
          >
            <Card.Section>
              <Stack vertical>
                <p>
                  The Free version of PugSync includes a limited amount of
                  features...
                </p>
                <Stack distribution="center">
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={RssIcon} />
                          <TextStyle variation="subdued">
                            1 active source to synchronize
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={BlogMajorMonotone} />
                          <TextStyle variation="subdued">
                            4 blog posts synchronized per month
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={ExchangeMajorMonotone} />
                          <TextStyle variation="subdued">
                            Synchronize your posts automatically
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={ImageMajorMonotone} />
                          <TextStyle variation="subdued">
                            Images are loaded from their original source
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                </Stack>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card
            title={
              <Stack spacing="tight" alignment="center">
                <Heading>Paid Plan</Heading>
                {active && <Badge status="info">Current Plan</Badge>}
              </Stack>
            }
            primaryFooterAction={
              !active && {
                content: "Subscribe for $3.99/month",
                onAction: () => this.createCharge(false),
                loading: chargeLoading
              }
            }
            secondaryFooterActions={
              active
                ? []
                : [
                    {
                      content: "1-Year plan for $39.99",
                      onAction: () => this.createCharge(true),
                      loading: chargeLoading
                    }
                  ]
            }
          >
            <Card.Section>
              <Stack vertical>
                <p>
                  Upgrade to the Paid version of PugSync to get the following
                  benefits...
                </p>
                <Stack distribution="center" spacing="tight">
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={RssIcon} />
                          <TextStyle variation="subdued">
                            Up to 10 sources to synchronize from
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={BlogMajorMonotone} />
                          <TextStyle variation="subdued">
                            Unlimited blog posts synchronized per month
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={ExchangeMajorMonotone} />
                          <TextStyle variation="subdued">
                            Synchronize your posts automatically
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={AnalyticsMajorMonotone} />
                          <TextStyle variation="subdued">
                            Analytics for all imported posts
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={ImageMajorMonotone} />
                          <TextStyle variation="subdued">
                            Images are hosted on PugSync's CDN
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                  <div style={{ width: 200, textAlign: "center" }}>
                    <Card>
                      <Card.Section>
                        <Stack
                          vertical
                          spacing="tight"
                          distribution="center"
                          alignment="center"
                        >
                          <Icon source={MediumIcon} />
                          <TextStyle variation="subdued">
                            Import all Medium posts with Bulk Import
                          </TextStyle>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </div>
                </Stack>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    );
  }

  render() {
    const { showPayment, initializing } = this.state;

    if (initializing) {
      return (
        <Page>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <p style={{ textAlign: "center" }}>
                  <Spinner size="large" color="teal" />
                </p>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      );
    }

    if (showPayment) {
      return (
        <Page
          title="Thank you for installing PugSync!"
          subtitle="You can use PugSync for free, or upgrade to our Paid Plan to unlock all our features."
        >
          {this.renderPlans()}
        </Page>
      );
    }

    return this.renderV2();
  }

  renderV2() {
    const {
      page,
      filter,
      selectedSource,
      sourceLoading,
      blogs,
      domain,
      showToast,
      toastContent,
      toastError,
      confirmDeleteSource,
      active,
      last_sources,
      sources,
      sourceSetup
    } = this.state;

    const sourceOptions = [
      { label: "Medium", value: "medium" },
      { label: "WordPress", value: "wordpress" },
      { label: "Squarespace", value: "squarespace" },
      { label: "Blogger", value: "blogger" },
      { label: "Wix", value: "wix" },
      { label: "Tumblr", value: "tumblr" },
      { label: "Webflow", value: "webflow" },
      { label: "Shopify Blog", value: "shopify" }
    ];

    const toastMarkup = showToast ? (
      <Toast
        content={toastContent}
        duration={3000}
        error={toastError}
        onDismiss={this.toggleToast}
      />
    ) : null;

    const paidItems = [
      {
        label: "Analytics",
        icon: AnalyticsMajorMonotone,
        onClick: () => this.getAnalytics()
      }
    ];

    const navigationMarkup = (
      <Navigation>
        <Navigation.Section
          separator
          title="PugSync"
          items={[
            {
              label: "Dashboard",
              icon: HomeMajorMonotone,
              onClick: () => this.setState({ page: "dashboard" })
            },
            {
              label: "Articles",
              icon: BlogMajorMonotone,
              onClick: () => this.getArticles()
            },
            ...(active ? paidItems : []),

            {
              label: "Account",
              icon: SettingsMajorMonotone,
              onClick: () => this.setState({ page: "account" })
            },
            {
              label: "What's new?",
              icon: CircleInformationMajorMonotone,
              onClick: () =>
                window.open(
                  "https://medium.com/creative-black-pug-studio/pugsync-version-3-is-out-6ae3662d633",
                  "_blank"
                )
            }
          ]}
        />
        <Navigation.Section
          separator
          title="Sources"
          items={[
            {
              label: "All",
              value: "all"
            }
          ]
            .concat(sourceOptions)
            .map(item => ({
              label: item.label,
              icon: sourceIcons[item.value],
              onClick: () => this.getSources(item.value),
              selected: page === "sources" && filter === item.value
            }))}
          action={
            this.canAddSource() && {
              accessibilityLabel: "Add a source",
              icon: CirclePlusOutlineMinor,
              onClick: () => this.openSource()
            }
          }
        />
      </Navigation>
    );

    const closeSource = () => this.setState({ selectedSource: null });

    const sourceMarkup = selectedSource && (
      <Modal
        open
        onClose={closeSource}
        loading={sourceLoading}
        title={`${selectedSource.id ? "Update" : "Add"} a source`}
        primaryAction={{
          content: "Save",
          onAction: this.saveSource,
          loading: sourceLoading
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeSource,
            disabled: sourceLoading
          }
        ]}
      >
        {selectedSource.type ? (
          <>
            <Modal.Section>
              <Stack vertical>
                <Stack.Item>
                  <Label>Source Type</Label>
                  <Stack spacing="tight" alignment="center">
                    <Icon
                      source={sourceIcons[selectedSource.type]}
                      accessibilityLabel={selectedSource.type}
                    />
                    <span style={{ textTransform: "capitalize" }}>
                      {selectedSource.type}
                    </span>
                    <Button
                      plain
                      primary
                      size="slim"
                      onClick={() =>
                        this.setState({
                          selectedSource: {
                            ...selectedSource,
                            type: ""
                          }
                        })
                      }
                    >
                      Change
                    </Button>
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <TextField
                    value={selectedSource.name}
                    onChange={name =>
                      this.setState({
                        selectedSource: {
                          ...selectedSource,
                          name
                        }
                      })
                    }
                    label="Source Name"
                    type="text"
                    helpText="Give a name to your source to find it easily"
                    maxLength={60}
                  />
                </Stack.Item>
                {!selectedSource.id && (
                  <Stack.Item>
                    <TextField
                      value={selectedSource.url}
                      onChange={url =>
                        this.setState({
                          selectedSource: {
                            ...selectedSource,
                            url
                          }
                        })
                      }
                      label="RSS Feed URL"
                      type="text"
                      maxLength={300}
                      helpText={
                        <>
                          <b>Setup Instructions:</b>{" "}
                          {sourceSetup[selectedSource.type]}
                        </>
                      }
                    />
                  </Stack.Item>
                )}
                <Stack.Item>
                  <Select
                    label="Targeted Blog"
                    options={blogs.map(b => ({
                      value: `${b.id}`,
                      label: b.title
                    }))}
                    onChange={blog_id =>
                      this.setState({
                        selectedSource: {
                          ...selectedSource,
                          blog_id: Number(blog_id)
                        }
                      })
                    }
                    value={`${selectedSource.blog_id}`}
                    helpText={
                      <p>
                        Your articles will be added to the selected Shopify
                        blog. You can also decide to create a{" "}
                        <a
                          href={`https://${domain}/admin/blogs/new`}
                          target="_blank"
                        >
                          new blog
                        </a>
                        .
                      </p>
                    }
                  />
                </Stack.Item>
              </Stack>
            </Modal.Section>
            <Modal.Section>
              <Stack vertical spacing="tight">
                <Subheading>Options</Subheading>
                <Checkbox
                  checked={selectedSource.active}
                  label="Active"
                  helpText={`You can only have ${
                    active ? 10 : 1
                  } active sources at a time.`}
                  disabled={
                    !active &&
                    selectedSource.id &&
                    (!!last_sources.find(
                      s => s.id !== selectedSource.id && s.active
                    ) ||
                      !!sources.find(
                        s => s.id !== selectedSource.id && s.active
                      ))
                  }
                  onChange={active =>
                    this.setState({
                      selectedSource: {
                        ...selectedSource,
                        active
                      }
                    })
                  }
                />
                <Checkbox
                  checked={selectedSource.redirect}
                  label="Redirect user to the source's website"
                  onChange={redirect =>
                    this.setState({
                      selectedSource: {
                        ...selectedSource,
                        redirect
                      }
                    })
                  }
                />
                <Checkbox
                  checked={selectedSource.autosync}
                  label="AutoSync this source"
                  onChange={autosync =>
                    this.setState({
                      selectedSource: {
                        ...selectedSource,
                        autosync
                      }
                    })
                  }
                />
                <Checkbox
                  checked={selectedSource.sync_as_draft}
                  label="Sync as Draft"
                  helpText="This option lets you synchronize your articles without publishing them on Shopify."
                  onChange={sync_as_draft =>
                    this.setState({
                      selectedSource: {
                        ...selectedSource,
                        sync_as_draft
                      }
                    })
                  }
                />
              </Stack>
            </Modal.Section>
          </>
        ) : (
          <Modal.Section>
            <Stack distribution="fillEvenly" spacing="tight">
              {sourceOptions.map(source => (
                <button
                  className="source-button"
                  type="button"
                  onClick={() =>
                    this.setState({
                      selectedSource: {
                        ...selectedSource,
                        type: source.value
                      }
                    })
                  }
                >
                  <Icon
                    source={sourceIcons[source.value]}
                    accessibilityLabel={source.value}
                  />
                  <div style={{ marginTop: 4 }}>
                    <TextStyle variation="subdued">{source.label}</TextStyle>
                  </div>
                </button>
              ))}
            </Stack>
          </Modal.Section>
        )}
      </Modal>
    );

    const deleteSourceMarkup = (
      <Modal
        open={Boolean(confirmDeleteSource)}
        onClose={() => this.setState({ confirmDeleteSource: null })}
        title="Delete a source"
        primaryAction={{
          content: "Delete",
          destructive: true,
          onAction: this.deleteSource,
          loading: sourceLoading
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => this.setState({ confirmDeleteSource: null }),
            disabled: sourceLoading
          }
        ]}
      >
        {confirmDeleteSource && (
          <Modal.Section>
            <Stack vertical>
              <Stack.Item>
                <p>
                  You are about to delete this source permanently. Press the
                  Delete button to delete.
                </p>
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label="Delete articles imported from this source"
                  checked={confirmDeleteSource.deleteArticles}
                  onChange={deleteArticles =>
                    this.setState({
                      confirmDeleteSource: {
                        ...confirmDeleteSource,
                        deleteArticles
                      }
                    })
                  }
                />
              </Stack.Item>
            </Stack>
          </Modal.Section>
        )}
      </Modal>
    );

    return (
      <Frame
        topBar={
          <TopBar
            secondaryMenu={
              <a
                style={{ display: "block", marginRight: 16 }}
                href={`https://pugsync.blackpugstudio.com/v3/faq.html`}
                title="Read our FAQ"
                target="_blank"
              >
                <Icon
                  source={QuestionMarkMajorMonotone}
                  accessibilityLabel="FAQ"
                />
              </a>
            }
          />
        }
        navigation={navigationMarkup}
      >
        {this.renderPage()}
        {sourceMarkup}
        {toastMarkup}
        {deleteSourceMarkup}
      </Frame>
    );
  }

  capitalize = (str = "") => {
    if (!str) {
      return "";
    }
    return str[0].toUpperCase() + str.substring(1);
  };

  renderSources = () => {
    const { sources, pageLoading, filter, active } = this.state;

    return (
      <Page
        title={`${filter ? `${this.capitalize(filter)} ` : ""}Sources`}
        primaryAction={{
          content: "Add",
          onAction: () =>
            this.openSource(undefined, filter === "all" ? null : filter),
          disabled: pageLoading || !this.canAddSource()
        }}
      >
        <Layout>
          <Layout.Section>
            {pageLoading && (
              <div style={{ textAlign: "center", padding: 48 }}>
                <Spinner size="large" color="teal" />
              </div>
            )}
            {!pageLoading &&
              (sources.length ? (
                <>
                  {!active && (
                    <>
                      <Banner
                        title="Did you know?"
                        status="info"
                        action={{
                          content: "Upgrade my account",
                          onAction: () => this.setState({ showPayment: true })
                        }}
                      >
                        <p>
                          <TextStyle variation="subdued">
                            You are currently limited to 1 active source.
                            <br />
                            Upgrade your account to synchronize more sources
                            simultaneously!
                          </TextStyle>
                        </p>
                      </Banner>
                      <div style={{ height: 24 }} />
                    </>
                  )}
                  {sources.map(source => {
                    return (
                      <Card
                        title={
                          <Stack alignment="center">
                            <Icon
                              source={sourceIcons[source.type]}
                              accessibilityLabel={source.type}
                            />
                            <Heading>{source.name}</Heading>
                          </Stack>
                        }
                        primaryFooterAction={
                          source.active
                            ? {
                                content: "Manual Sync",
                                loading: source.loading,
                                onAction: () => this.syncSource(source)
                              }
                            : {
                                content: "Activate",
                                loading: source.loading,
                                onAction: () => this.activateSource(source),
                                disabled:
                                  !active && !!sources.find(s => s.active)
                              }
                        }
                        secondaryFooterActions={[
                          {
                            content: "View articles",
                            onAction: () => this.getArticles(source.id)
                          },
                          {
                            content: "Edit",
                            onAction: () => this.openSource(source)
                          },
                          {
                            content: "Delete",
                            destructive: true,
                            onAction: () =>
                              this.setState({
                                confirmDeleteSource: {
                                  source,
                                  deleteArticles: false
                                }
                              })
                          }
                        ]}
                      >
                        <Card.Section>
                          <Stack vertical spacing="tight">
                            <Subheading>
                              {this.getSourceCount(source, true)} -{" "}
                              {source.last_sync
                                ? `Last synced on ${format(
                                    new Date(source.last_sync),
                                    "MMM d, H:mm"
                                  )}`
                                : "Never synced"}
                            </Subheading>
                            <Stack.Item>
                              <Stack alignment="center" spacing="tight">
                                <Icon source={LinkMinor} />
                                <span>{source.url}</span>
                              </Stack>
                            </Stack.Item>
                            <Stack.Item>
                              <Badge
                                status={source.active ? "success" : "warning"}
                              >
                                {source.active ? "Enabled" : "Disabled"}
                              </Badge>
                              {source.redirect && (
                                <Badge status="info">Redirect</Badge>
                              )}
                              {source.autosync && (
                                <Badge status="info">Autosync</Badge>
                              )}
                              {source.sync_as_draft && (
                                <Badge status="info">Sync as draft</Badge>
                              )}
                            </Stack.Item>
                          </Stack>
                        </Card.Section>
                      </Card>
                    );
                  })}
                </>
              ) : (
                <EmptyState
                  heading="No Sources Found"
                  action={{
                    content: "Add a source",
                    onAction: () => this.openSource(undefined, filter)
                  }}
                  image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                >
                  <p>
                    Create your first{" "}
                    {filter !== "all" && (
                      <span style={{ textTransform: "capitalize" }}>
                        {filter}{" "}
                      </span>
                    )}
                    source and start synchronizing your articles now!
                  </p>
                </EmptyState>
              ))}
          </Layout.Section>
          <Layout.Section secondary>
            {!pageLoading &&
              sources.findIndex(s => s.type === "medium") > -1 &&
              this.renderTools()}
          </Layout.Section>
        </Layout>
      </Page>
    );
  };

  renderArticles = () => {
    const {
      articles,
      pageLoading,
      domain,
      confirmDeleteArticle,
      articleLoading,
      articlePage,
      sourceFilter,
      sources,
      articlesPageInfo,
      active,
      monthlyPosts
    } = this.state;
    const limitReached = !active && 4 - monthlyPosts <= 0;
    const source = sources.find(s => s.id === sourceFilter);

    const deleteArticleMarkup = (
      <Modal
        open={Boolean(confirmDeleteArticle)}
        onClose={() => this.setState({ confirmDeleteArticle: null })}
        title="Delete an article"
        primaryAction={{
          content: "Delete",
          destructive: true,
          onAction: this.deleteArticle,
          loading: articleLoading
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => this.setState({ confirmDeleteArticle: null }),
            disabled: articleLoading
          }
        ]}
      >
        {confirmDeleteArticle && (
          <Modal.Section>
            <Stack vertical>
              <Stack.Item>
                <p>
                  You are about to delete this article permanently. Press the
                  Delete button to delete.
                </p>
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label="Do NOT resynchronize this article"
                  helpText="Check this box if you don't want AutoSync to reimport this article automatically."
                  checked={confirmDeleteArticle.noResync}
                  onChange={noResync =>
                    this.setState({
                      confirmDeleteArticle: {
                        ...confirmDeleteArticle,
                        noResync
                      }
                    })
                  }
                />
              </Stack.Item>
            </Stack>
          </Modal.Section>
        )}
      </Modal>
    );

    return (
      <Page title={`Articles${source ? ` from ${source.name}` : ""}`} fullWidth>
        <Layout>
          <Layout.Section>
            {pageLoading && (
              <div style={{ textAlign: "center", padding: 48 }}>
                <Spinner size="large" color="teal" />
              </div>
            )}
            {!pageLoading &&
              (articles.length ? (
                <Stack vertical>
                  {!active && (
                    <Banner
                      title="Did you know?"
                      status={limitReached ? "warning" : "info"}
                      action={{
                        content: "Upgrade my account",
                        onAction: () => this.setState({ showPayment: true })
                      }}
                    >
                      <p>
                        {limitReached ? (
                          <TextStyle variation="subdued">
                            You have reached your limit of 4 monthly
                            synchronized articles.
                            <br />
                            Upgrade now to remove this limit completely!
                          </TextStyle>
                        ) : (
                          <TextStyle variation="subdued">
                            You can unlock Analytics for articles synchronized
                            with PugSync
                            <br />
                            and follow metrics such as Views, Average Read Time,
                            Links Opened and more!
                          </TextStyle>
                        )}
                      </p>
                    </Banner>
                  )}
                  <Card sectioned>
                    <div
                      style={
                        articleLoading
                          ? { pointerEvents: "none", opacity: 0.5 }
                          : {}
                      }
                    >
                      <DataTable
                        columnContentTypes={
                          active
                            ? ["text", "numeric", "numeric", "text"]
                            : ["text", "text"]
                        }
                        headings={
                          active
                            ? ["Title", "Views", "Avg. Time", ""]
                            : ["Title", ""]
                        }
                        verticalAlign="middle"
                        footerContent={
                          <Pagination
                            hasPrevious={!!articlesPageInfo?.hasPrevious}
                            hasNext={!!articlesPageInfo?.hasNext}
                            onPrevious={() =>
                              this.getArticles(sourceFilter, articlePage - 1)
                            }
                            onNext={() =>
                              this.getArticles(sourceFilter, articlePage + 1)
                            }
                          >
                            {articlesPageInfo
                              ? `Page ${articlePage} of ${(articlesPageInfo.totalCount /
                                  10) |
                                  0}`
                              : ""}
                          </Pagination>
                        }
                        rows={articles.map(article => {
                          const title = (
                            <div
                              style={{
                                maxWidth: 360,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {article.title}
                            </div>
                          );
                          const actions = (
                            <Stack spacing="tight" distribution="trailing">
                              {active && (
                                <Button
                                  size="slim"
                                  disabled={articleLoading}
                                  onClick={() => this.getAnalytics(1, article)}
                                >
                                  Analytics
                                </Button>
                              )}
                              <Button
                                size="slim"
                                disabled={articleLoading}
                                url={`https://${domain}/admin/articles/${
                                  article.id
                                }`}
                                external
                              >
                                Edit
                              </Button>
                              <Button
                                size="slim"
                                disabled={articleLoading}
                                onClick={() =>
                                  this.publishArticle(
                                    article.id,
                                    !article.published
                                  )
                                }
                              >
                                {article.published ? "Unpublish" : "Publish"}
                              </Button>
                              <Button
                                size="slim"
                                disabled={articleLoading}
                                onClick={() =>
                                  this.setState({
                                    confirmDeleteArticle: {
                                      article,
                                      noResync: false
                                    }
                                  })
                                }
                                destructive
                              >
                                Delete
                              </Button>
                            </Stack>
                          );
                          if (!active) {
                            return [title, actions];
                          }
                          const views = article.events.filter(
                            event => event.type === "PAGE_VIEW"
                          );
                          const time = views.length
                            ? views.reduce((time, event) => {
                                return (
                                  time +
                                  (new Date(event.last_update) -
                                    new Date(event.created)) /
                                    1000
                                );
                              }, 0) / views.length
                            : 0;
                          return [
                            title,
                            views.length,
                            time >= 60
                              ? `${Math.ceil(time / 60)}m`
                              : `${Math.ceil(time)}s`,
                            actions
                          ];
                        })}
                      />
                    </div>
                  </Card>
                </Stack>
              ) : (
                <EmptyState
                  heading="No articles synchronized yet..."
                  image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                  action={{
                    content: "Create a source",
                    onAction: () => this.openSource()
                  }}
                >
                  <p>Create a new Source and start importing articles!</p>
                </EmptyState>
              ))}
          </Layout.Section>
        </Layout>
        {deleteArticleMarkup}
      </Page>
    );
  };

  updateAnalyticsRange = range => {
    this.setState({ analyticsRange: range }, () => {
      this.getAnalytics(1, this.state.analyticsPost);
    });
  };

  renderAnalytics = () => {
    const {
      pageLoading,
      articleLoading,
      articlePage,
      analyticsPost,
      analytics,
      analyticsRange
    } = this.state;

    return (
      <Page
        title="Analytics"
        subtitle={`${
          analyticsPost ? `Article "${analyticsPost.title}" - ` : ""
        }From ${format(analyticsRange[0], "MMM d, yyyy")} To ${format(
          analyticsRange[1],
          "MMM d, yyyy"
        )}`}
        primaryAction={
          analyticsPost && {
            content: "View all",
            primary: false,
            onAction: () => this.getAnalytics()
          }
        }
      >
        <Layout>
          <Layout.Section>
            {pageLoading && (
              <div style={{ textAlign: "center", padding: 48 }}>
                <Spinner size="large" color="teal" />
              </div>
            )}
            {!pageLoading && analytics && (
              <div
                style={
                  articleLoading ? { pointerEvents: "none", opacity: 0.5 } : {}
                }
              >
                <Stack vertical distribution="fill">
                  <Stack>
                    <TextField
                      type="date"
                      label="Start"
                      value={format(analyticsRange[0], "yyyy-MM-dd")}
                      max={format(analyticsRange[1], "yyyy-MM-dd")}
                      onChange={value =>
                        this.updateAnalyticsRange([
                          new Date(value),
                          analyticsRange[1]
                        ])
                      }
                    />
                    <TextField
                      type="date"
                      label="End"
                      value={format(analyticsRange[1], "yyyy-MM-dd")}
                      min={format(analyticsRange[0], "yyyy-MM-dd")}
                      onChange={value =>
                        this.updateAnalyticsRange([
                          analyticsRange[0],
                          new Date(value)
                        ])
                      }
                    />
                  </Stack>
                  <Stack distribution="fillEvenly">
                    <Banner title={`${analytics.totalViews}`} status="info">
                      <p>
                        <TextStyle variation="subdued">Total Views</TextStyle>
                      </p>
                    </Banner>
                    <Banner
                      title={
                        analytics.averageDuration >= 60
                          ? `${Math.ceil(analytics.averageDuration / 60)}m`
                          : `${analytics.averageDuration}s`
                      }
                      status="info"
                    >
                      <p>
                        <TextStyle variation="subdued">
                          Average Read Time
                        </TextStyle>
                      </p>
                    </Banner>
                    <Banner title={`${analytics.currentViews}`} status="info">
                      <p>
                        <TextStyle variation="subdued">
                          Current Readers
                        </TextStyle>
                      </p>
                    </Banner>
                  </Stack>
                  <Card
                    sectioned
                    title={
                      analyticsPost ? "Readers Also Read" : "Articles Read"
                    }
                  >
                    {analytics.articles.length ? (
                      <DataTable
                        columnContentTypes={[
                          "text",
                          "numeric",
                          "numeric",
                          "numeric",
                          "numeric"
                        ]}
                        headings={[
                          "Title",
                          "Views",
                          "Avg. Time",
                          "Links Opened",
                          ""
                        ]}
                        verticalAlign="middle"
                        footerContent={
                          <Pagination
                            hasPrevious={analytics.hasPrevious}
                            hasNext={analytics.hasNext}
                            onPrevious={() =>
                              this.getAnalytics(articlePage - 1, analyticsPost)
                            }
                            onNext={() =>
                              this.getAnalytics(articlePage + 1, analyticsPost)
                            }
                          >{`Page ${articlePage} of ${(analytics.totalCount /
                            10) |
                            0}`}</Pagination>
                        }
                        rows={analytics.articles.map(article => {
                          const time = article.views
                            ? article.totalDuration / article.views
                            : 0;
                          return [
                            <div
                              style={{
                                maxWidth: 360,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {article.post.title}
                            </div>,
                            article.views,
                            time >= 60
                              ? `${Math.ceil(time / 60)}m`
                              : `${Math.ceil(time)}s`,
                            article.linksOpened,
                            <Button
                              size="slim"
                              onClick={() => this.getAnalytics(1, article.post)}
                            >
                              View details
                            </Button>
                          ];
                        })}
                      />
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        <TextStyle variation="subdued">
                          No articles read yet...
                        </TextStyle>
                      </p>
                    )}
                  </Card>
                  <Card sectioned title="Links Opened">
                    {analytics.links.length ? (
                      <DataTable
                        columnContentTypes={["text", "numeric"]}
                        headings={["Link", "Count"]}
                        verticalAlign="middle"
                        rows={analytics.links.map(link => {
                          return [
                            <Link url={link.url} external>
                              {link.url}
                            </Link>,
                            link.count
                          ];
                        })}
                      />
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        <TextStyle variation="subdued">
                          No links opened yet...
                        </TextStyle>
                      </p>
                    )}
                  </Card>
                </Stack>
              </div>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    );
  };

  canAddSource = () => {
    const { active, sources, last_sources } = this.state;
    return (
      active ||
      (!sources.find(s => s.active) && !last_sources.find(s => s.active))
    );
  };

  renderAccount = () => {
    const {
      cancelLoading,
      cancelModal,
      yearly,
      verified,
      active,
      newsletter,
      newsletterLoading,
      post_ids,
      newsletterModal,
      sourcesCount,
      name,
      domain,
      monthlyPosts,
      created
    } = this.state;

    return (
      <Page title="Account">
        <Stack vertical>
          <Card title={name}>
            <Card.Section>
              <Stack vertical spacing="tight">
                <div>
                  <b>Domain:</b> {domain}
                </div>
                <div>
                  <b>Created on:</b> {format(new Date(created), "PPpp")}
                </div>
                <div>
                  <b>Account status:</b>{" "}
                  <Badge status={active ? "success" : "warning"}>
                    {active ? "Paid" : "Free"}
                  </Badge>
                </div>
                {active && (
                  <div>
                    <b>Payment Schedule:</b> {yearly ? "Yearly" : "Monthly"}
                  </div>
                )}
              </Stack>
            </Card.Section>
          </Card>
          {!active && (
            <Card
              title="Subscription"
              primaryFooterAction={{
                content: "Upgrade my account",
                onAction: () => this.setState({ showPayment: true })
              }}
            >
              <Card.Section>
                <p>
                  <TextStyle variation="subdued">
                    Subscribe to our Paid Plan and unlock features like
                    <br />
                    Analytics, Image Hosting, Unlimited Sources & Articles!
                  </TextStyle>
                </p>
              </Card.Section>
            </Card>
          )}
          <Stack distribution="fillEvenly">
            <Banner
              title={`${sourcesCount}`}
              status="info"
              action={{
                content: "View sources",
                onAction: () => this.getSources("all")
              }}
            >
              <p>
                <TextStyle variation="subdued">
                  Active Sources
                  <br />
                  Max. Active Sources: <b>{active ? 10 : 1}</b>
                </TextStyle>
              </p>
            </Banner>
            <Banner
              title={`${post_ids.filter(post => !post.deleted).length}`}
              status="info"
              action={{
                content: "View articles",
                onAction: () => this.getArticles()
              }}
            >
              <p>
                <TextStyle variation="subdued">
                  Imported Articles
                  <br />
                  Remaining Monthly Syncs:{" "}
                  <b>{active ? "Unlimited" : Math.max(4 - monthlyPosts, 0)}</b>
                </TextStyle>
              </p>
            </Banner>
            <Banner
              title="Newsletter"
              status={newsletter ? "success" : ""}
              action={{
                content: newsletter ? "Unsubscribe" : "Subscribe",
                onAction: () => this.setNewsletter(!newsletter),
                loading: newsletterLoading
              }}
            >
              <p>
                <TextStyle variation="subdued">
                  {newsletter ? (
                    <>
                      Your are currently subscribed
                      <br />
                      to our newsletter.
                    </>
                  ) : (
                    <>
                      Subscribe to our newsletter
                      <br />
                      and get our latest updates!
                    </>
                  )}
                </TextStyle>
              </p>
            </Banner>
          </Stack>
          <Card sectioned>
            <Stack vertical spacing="tight">
              <Heading>Customer support</Heading>
              <p>
                Having a problem using the app? No problem! Check out our{" "}
                <Link
                  url="https://pugsync.blackpugstudio.com/v3/faq.html"
                  external
                >
                  FAQ
                </Link>{" "}
                or send us an email at{" "}
                <Link url="mailto:helpdesk@blackpugstudio.com" external>
                  helpdesk@blackpugstudio.com
                </Link>{" "}
                and we'll get back to you as soon as possible.
              </p>
            </Stack>
          </Card>
          {this.renderPlans()}
          {active &&
            (yearly ? (
              <Card title="Subscription">
                <Card.Section>
                  <p>
                    <TextStyle variation="subdued">
                      You have subscribed to our 1-Year plan. You will have full
                      access to our service until{" "}
                      <b>{new Date(verified).toString()}</b>. We will not
                      automatically bill you at the end of your yearly plan, you
                      will have the option to resubscribe instead.
                    </TextStyle>
                  </p>
                </Card.Section>
              </Card>
            ) : (
              <Card
                title="Subscription"
                secondaryFooterActions={[
                  {
                    content: "Cancel my subscription",
                    onAction: () => this.setState({ cancelModal: true }),
                    loading: cancelLoading
                  }
                ]}
              >
                <Card.Section>
                  <p>
                    <TextStyle variation="subdued">
                      Monthly subscription charge:
                      <br />
                      <b>3.99/mo</b>.
                    </TextStyle>
                  </p>
                </Card.Section>
              </Card>
            ))}
        </Stack>
        <Modal
          open={cancelModal}
          onClose={() => this.setState({ cancelModal: false })}
          title="Cancel your subscription"
          secondaryActions={[
            {
              content: "Confirm cancellation",
              onAction: this.cancelSubscription,
              loading: cancelLoading
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                We will not delete your blog posts but we will stop
                synchronizing your publications.
                <br />
                Are you sure you want to cancel your subscription?
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <Modal
          open={newsletterModal}
          onClose={() => this.setState({ newsletterModal: false })}
          title="Newsletter subscription"
          secondaryActions={[
            {
              content: "Confirm",
              onAction: this.setNewsletter,
              loading: newsletterLoading
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Thank you for installing PugSync!</p>
              <p>
                Subscribe to our newsletter to receive the latest news and
                exclusive offers regularly. No spam.
              </p>
              <Checkbox
                checked={newsletter}
                label="I agree to receive the PugSync newsletter"
                onChange={this.handleChange("newsletter")}
              />
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Page>
    );
  };

  renderDashboard = () => {
    const {
      cancelLoading,
      cancelModal,
      yearly,
      verified,
      active,
      last_sources,
      newsletter,
      newsletterLoading,
      post_ids,
      newsletterModal,
      currentReaders,
      domain,
      recentArticles,
      monthlyPosts
    } = this.state;

    return (
      <Page title="Dashboard" fullWidth>
        <Stack vertical>
          <Stack distribution="fillEvenly">
            {!active ? (
              <Banner
                title="Subscription"
                status="warning"
                action={{
                  content: "Upgrade my account",
                  onAction: () => this.setState({ showPayment: true })
                }}
              >
                <p>
                  <TextStyle variation="subdued">
                    Subscribe to our Paid Plan and unlock features like
                    <br />
                    Analytics, Image Hosting, Unlimited Sources & Articles!
                  </TextStyle>
                </p>
              </Banner>
            ) : yearly ? (
              <Banner title="Subscription" status="success">
                <p>
                  <TextStyle variation="subdued">
                    You have subscribed to our 1-Year plan. You will have full
                    access to our service until{" "}
                    <b>{new Date(verified).toString()}</b>. We will not
                    automatically bill you at the end of your yearly plan, you
                    will have the option to resubscribe instead.
                  </TextStyle>
                </p>
              </Banner>
            ) : (
              <Banner
                title="Subscription"
                status="success"
                action={{
                  content: "View account",
                  onAction: () => this.setState({ page: "account" })
                }}
              >
                <p>
                  <TextStyle variation="subdued">
                    Monthly subscription charge:
                    <br />
                    <b>3.99/mo</b>.
                  </TextStyle>
                </p>
              </Banner>
            )}
            <Banner
              title={`${post_ids.filter(post => !post.deleted).length}`}
              status="info"
              action={{
                content: "View articles",
                onAction: () => this.getArticles()
              }}
            >
              <p>
                <TextStyle variation="subdued">
                  Imported Articles
                  <br />
                  Remaining Monthly Syncs:{" "}
                  <b>{active ? "Unlimited" : Math.max(4 - monthlyPosts, 0)}</b>
                </TextStyle>
              </p>
            </Banner>
            {active && (
              <Banner
                title={`${currentReaders}`}
                status="info"
                action={{
                  content: "View analytics",
                  onAction: () => this.getAnalytics()
                }}
              >
                <p>
                  <TextStyle variation="subdued">
                    Visitors currently reading
                    <br />
                    your synchronized articles.
                  </TextStyle>
                </p>
              </Banner>
            )}
            <Banner
              title="Newsletter"
              status={newsletter ? "success" : ""}
              action={{
                content: newsletter ? "Unsubscribe" : "Subscribe",
                onAction: () => this.setNewsletter(!newsletter),
                loading: newsletterLoading
              }}
            >
              <p>
                <TextStyle variation="subdued">
                  {newsletter ? (
                    <>
                      Your are currently subscribed
                      <br />
                      to our newsletter.
                    </>
                  ) : (
                    <>
                      Subscribe to our newsletter
                      <br />
                      and get our latest updates!
                    </>
                  )}
                </TextStyle>
              </p>
            </Banner>
          </Stack>
          <Layout>
            <Layout.Section oneHalf>
              <Card
                sectioned
                primaryFooterAction={
                  !last_sources.length && {
                    content: "Add a source",
                    onAction: () => this.openSource()
                  }
                }
                secondaryFooterActions={
                  last_sources.length
                    ? [
                        {
                          content: "View all sources",
                          onAction: () => this.getSources("all")
                        }
                      ]
                    : []
                }
              >
                <Heading>Active sources</Heading>
                {last_sources.length ? (
                  last_sources.map(source => (
                    <Card.Section
                      title={
                        <Stack alignment="center" spacing="tight">
                          <Icon
                            source={sourceIcons[source.type]}
                            accessibilityLabel={source.type}
                          />
                          <Heading>{source.name}</Heading>
                        </Stack>
                      }
                      actions={[
                        {
                          content: "Edit",
                          onAction: () => this.openSource(source)
                        },
                        {
                          content: "Sync",
                          onAction: () =>
                            this.syncSource(source, "last_sources"),
                          loading: source.loading
                        }
                      ]}
                    >
                      <Stack vertical spacing="tight">
                        <Subheading>
                          {this.getSourceCount(source, true)} -{" "}
                          {source.last_sync
                            ? `Last synced on ${format(
                                new Date(source.last_sync),
                                "MMM d, H:mm"
                              )}`
                            : "Never synced"}
                        </Subheading>
                        <Stack.Item>
                          <Badge status={source.active ? "success" : "warning"}>
                            {source.active ? "Enabled" : "Disabled"}
                          </Badge>
                          {source.redirect && (
                            <Badge status="info">Redirect</Badge>
                          )}
                          {source.autosync && (
                            <Badge status="info">Autosync</Badge>
                          )}
                        </Stack.Item>
                      </Stack>
                    </Card.Section>
                  ))
                ) : (
                  <Card.Section>
                    <div style={{ textAlign: "center" }}>
                      <TextStyle variation="subdued">
                        You dont't have any active sources yet.
                        <br />
                        Create a source to start importing articles!
                      </TextStyle>
                    </div>
                  </Card.Section>
                )}
              </Card>
              <Card sectioned>
                <Stack vertical spacing="tight">
                  <Heading>Customer support</Heading>
                  <p>
                    Having a problem using the app? No problem! Check out our{" "}
                    <Link
                      url="https://pugsync.blackpugstudio.com/v3/faq.html"
                      external
                    >
                      FAQ
                    </Link>{" "}
                    or send us an email at{" "}
                    <Link url="mailto:helpdesk@blackpugstudio.com" external>
                      helpdesk@blackpugstudio.com
                    </Link>{" "}
                    and we'll get back to you as soon as possible.
                  </p>
                </Stack>
              </Card>
            </Layout.Section>
            <Layout.Section oneHalf>
              <Card
                sectioned
                secondaryFooterActions={[
                  {
                    content: "View all articles",
                    onAction: () => this.getArticles()
                  }
                ]}
              >
                <Heading>Recent articles</Heading>
                {recentArticles.length ? (
                  recentArticles.map(article => {
                    const views = article.events.filter(
                      event => event.type === "PAGE_VIEW"
                    ).length;
                    return (
                      <Card.Section
                        key={article.id}
                        title={
                          <Heading element="h3">
                            <div
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {article.title}
                            </div>
                          </Heading>
                        }
                        actions={[
                          ...(active
                            ? [
                                {
                                  content: "Analytics",
                                  onAction: () => this.getAnalytics(1, article)
                                }
                              ]
                            : []),
                          {
                            content: "Edit",
                            url: `https://${domain}/admin/articles/${
                              article.id
                            }`,
                            external: true
                          }
                        ]}
                      >
                        {active && (
                          <TextStyle variation="subdued">
                            {views} view{views > 1 ? "s" : ""}
                          </TextStyle>
                        )}
                      </Card.Section>
                    );
                  })
                ) : (
                  <Card.Section>
                    <p style={{ textAlign: "center" }}>
                      <TextStyle variation="subdued">
                        No articles synchronized yet...
                      </TextStyle>
                    </p>
                  </Card.Section>
                )}
              </Card>
            </Layout.Section>
          </Layout>
        </Stack>
        <Modal
          open={cancelModal}
          onClose={() => this.setState({ cancelModal: false })}
          title="Cancel your subscription"
          secondaryActions={[
            {
              content: "Confirm cancellation",
              onAction: this.cancelSubscription,
              loading: cancelLoading
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                We will not delete your blog posts but we will stop
                synchronizing your publications.
                <br />
                Are you sure you want to cancel your subscription?
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <Modal
          open={newsletterModal}
          onClose={() => this.setState({ newsletterModal: false })}
          title="Newsletter subscription"
          secondaryActions={[
            {
              content: "Confirm",
              onAction: this.setNewsletter,
              loading: newsletterLoading
            }
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Thank you for installing PugSync!</p>
              <p>
                Subscribe to our newsletter to receive the latest news and
                exclusive offers regularly. No spam.
              </p>
              <Checkbox
                checked={newsletter}
                label="I agree to receive the PugSync newsletter"
                onChange={this.handleChange("newsletter")}
              />
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Page>
    );
  };

  renderTools = () => {
    const {
      medium_import,
      active,
      mediumImportRequest,
      importLoading,
      domain,
      blogs
    } = this.state;

    const mediumImportMarkup = (
      <Modal
        open={Boolean(mediumImportRequest)}
        onClose={() => this.setState({ mediumImportRequest: null })}
        title="Medium Bulk Import"
        primaryAction={{
          content: "Import",
          onAction: this.importPostsV2,
          loading: importLoading
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => this.setState({ mediumImportRequest: null }),
            disabled: importLoading
          }
        ]}
      >
        {mediumImportRequest && (
          <Modal.Section>
            <Stack vertical>
              <Stack.Item>
                <Select
                  label="Targeted Blog"
                  options={blogs.map(b => ({
                    value: `${b.id}`,
                    label: b.title
                  }))}
                  onChange={blog_id =>
                    this.setState({
                      mediumImportRequest: {
                        ...mediumImportRequest,
                        blog_id: Number(blog_id)
                      }
                    })
                  }
                  value={`${mediumImportRequest.blog_id}`}
                  helpText={
                    <p>
                      You can also decide to create a{" "}
                      <a
                        href={`https://${domain}/admin/blogs/new`}
                        target="_blank"
                      >
                        new blog
                      </a>
                      .
                    </p>
                  }
                />
              </Stack.Item>
              <Stack.Item>
                <label style={{ display: "block", marginBottom: "0.4rem" }}>
                  Medium exported archive
                </label>
                <Stack alignment="center">
                  <Button primary onClick={this.openImport}>
                    Upload your archive
                  </Button>
                  <p>
                    {mediumImportRequest.file && mediumImportRequest.file.name}
                  </p>
                  <input
                    ref={this.uploader}
                    type="file"
                    accept=".zip"
                    style={{ display: "none" }}
                    onChange={e => {
                      const [file] = e.target.files;
                      if (file) {
                        this.setState({
                          mediumImportRequest: {
                            ...mediumImportRequest,
                            file
                          }
                        });
                      }
                      e.target.value = "";
                    }}
                  />
                </Stack>
              </Stack.Item>
            </Stack>
          </Modal.Section>
        )}
      </Modal>
    );

    return (
      <>
        <SettingToggle
          action={
            !active
              ? {
                  content: "Upgrade my account",
                  onAction: () => this.setState({ showPayment: true })
                }
              : {
                  content: medium_import
                    ? medium_import.count === medium_import.total
                      ? "Close"
                      : "Cancel"
                    : "Import",
                  onAction: medium_import
                    ? this.cancelImport
                    : () =>
                        this.setState({
                          mediumImportRequest: {
                            blog_id: blogs[0] ? blogs[0].id : null,
                            file: null
                          }
                        }),
                  loading: importLoading
                }
          }
          enabled={active && Boolean(medium_import)}
        >
          <Stack vertical spacing="tight">
            <Heading>Medium Bulk Import</Heading>
            {medium_import ? (
              <div style={{ margin: "8px 0" }}>
                <ProgressBar
                  size="small"
                  progress={Math.floor(
                    (medium_import.count / medium_import.total) * 100
                  )}
                />
                <p>
                  Imported{" "}
                  <TextStyle variation="strong">
                    {medium_import.count}
                  </TextStyle>{" "}
                  out of{" "}
                  <TextStyle variation="strong">
                    {medium_import.total} posts
                  </TextStyle>
                  . This action may take a few minutes.
                </p>
              </div>
            ) : (
              <>
                <p>
                  You can{" "}
                  <Link url="https://medium.com/me/export" external>
                    export all your Medium posts
                  </Link>{" "}
                  and import them manually using this feature! Read more about
                  this feature on our{" "}
                  <Link
                    url="https://pugsync.blackpugstudio.com/v3/faq.html"
                    external
                  >
                    FAQ page
                  </Link>
                  .
                </p>
              </>
            )}
          </Stack>
        </SettingToggle>
        {mediumImportMarkup}
      </>
    );
  };

  renderPage = () => {
    const { page } = this.state;
    switch (page) {
      case "dashboard":
        return this.renderDashboard();
      case "sources":
        return this.renderSources();
      case "articles":
        return this.renderArticles();
      case "analytics":
        return this.renderAnalytics();
      case "account":
        return this.renderAccount();
      default:
        return "";
    }
  };

  openSource = (source, sourceType) => {
    const [blog] = this.state.blogs;
    this.setState({
      selectedSource: source
        ? {
            ...source,
            sync_as_draft: source.sync_as_draft || false,
          }
        : {
            name: "",
            type: sourceType || "",
            url: "",
            blog_id: blog ? blog.id : null,
            redirect: false,
            autosync: false,
            sync_as_draft: false,
            active: true,
          }
    });
  };

  getSourceCount = (source, isLabel) => {
    const { post_ids } = this.state;
    const posts = post_ids.filter(post => post.source_id === source.id);
    const count = posts.length;
    if (isLabel) return `${count} article${count > 1 ? "s" : ""}`;
    return count;
  };

  saveSource = async () => {
    const { sourceLoading, selectedSource, sources } = this.state;
    if (sourceLoading || !selectedSource) return;
    this.setState({ sourceLoading: true });
    const sourceData = await this.fetch(
      `${this.state.api}/api/sources${
        selectedSource.id ? `/${selectedSource.id}` : ""
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(selectedSource)
      }
    );
    if (sourceData.ok) {
      const source = await sourceData.json();
      source.loading = false;
      this.setState({
        sources: sources
          .filter(s => s.id !== source.id)
          .concat(source)
          .sort(
            (a, b) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          ),
        selectedSource: null
      });
      this.getSettings();
    } else {
      let message;
      try {
        const error = await sourceData.json();
        message = error.message;
      } catch (e) {
        message = "Unknown error.";
      }
      this.toggleToast(message, true);
    }
    this.setState({ sourceLoading: false });
  };

  syncSource = async (source, target = "sources") => {
    const sources = [...this.state[target]];
    const index = sources.findIndex(s => s.id === source.id);
    sources[index].loading = true;
    this.setState({ sources });
    const sourceData = await this.fetch(
      `${this.state.api}/api/sources/${source.id}/sync`,
      { method: "POST" }
    );
    if (sourceData.ok) {
      const updatedSource = await sourceData.json();
      const count =
        updatedSource.post_count -
        (sources[index].post_count || this.getSourceCount(sources[index]));
      sources[index] = { ...updatedSource, loading: false };
      this.setState({ [target]: sources });
      this.toggleToast(
        `${count} new article(s) imported from ${updatedSource.name}!`
      );
      this.getSettings();
    } else {
      sources[index] = { ...source, loading: false };
      this.setState({ [target]: sources });
    }
  };

  activateSource = async source => {
    const sources = [...this.state.sources];
    const index = sources.findIndex(s => s.id === source.id);
    sources[index].loading = true;
    this.setState({ sources });
    const sourceData = await this.fetch(
      `${this.state.api}/api/sources/${source.id}/activate`,
      { method: "POST" }
    );
    if (sourceData.ok) {
      const updatedSource = await sourceData.json();
      sources[index] = { ...updatedSource, loading: false };
      this.setState({ sources });
      this.toggleToast(`${updatedSource.name} is now active!`);
      this.getSettings();
    } else {
      sources[index] = { ...source, loading: false };
      this.setState({ sources });
    }
  };

  deleteSource = async () => {
    const { confirmDeleteSource, sources, sourceLoading } = this.state;
    if (sourceLoading) return;
    const { source, deleteArticles } = confirmDeleteSource;
    this.setState({ sourceLoading: true });
    const sourceData = await this.fetch(
      `${this.state.api}/api/sources/${source.id}${
        deleteArticles ? "?deleteArticles=1" : ""
      }`,
      {
        method: "DELETE"
      }
    );
    if (sourceData.ok) {
      const deletedSource = await sourceData.json();
      this.setState({
        sources: sources.filter(s => s.id !== deletedSource.id),
        confirmDeleteSource: null
      });
      this.getSettings();
    } else {
      let message;
      try {
        const error = await sourceData.json();
        message = error.message;
      } catch (e) {
        message = "Unknown error.";
      }
      this.toggleToast(message, true);
    }
    this.setState({ sourceLoading: false });
  };

  publishArticle = async (articleId, published) => {
    const { articleLoading, sourceFilter, articlePage } = this.state;
    if (articleLoading) return;
    this.setState({ articleLoading: true });
    const articleData = await this.fetch(
      `${this.state.api}/api/articles/${articleId}/publish`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          published
        })
      }
    );
    if (articleData.ok) {
      await this.getArticles(sourceFilter, articlePage);
      this.toggleToast("Article updated!");
    } else {
      let message;
      try {
        const error = await articleData.json();
        message = error.message;
      } catch (e) {
        message = "Unknown error.";
      }
      this.toggleToast(message, true);
    }
    this.setState({ articleLoading: false });
  };

  deleteArticle = async () => {
    const {
      confirmDeleteArticle,
      articleLoading,
      sourceFilter,
      articlePage
    } = this.state;
    if (articleLoading) return;
    const { article, noResync } = confirmDeleteArticle;
    this.setState({ articleLoading: true });
    const articleData = await this.fetch(
      `${this.state.api}/api/articles/${article.id}${
        noResync ? "?noResync=1" : ""
      }`,
      {
        method: "DELETE"
      }
    );
    if (articleData.ok) {
      await this.getArticles(sourceFilter, articlePage);
      this.setState({
        confirmDeleteArticle: null
      });
      this.toggleToast("Article deleted!");
    } else {
      let message;
      try {
        const error = await articleData.json();
        message = error.message;
      } catch (e) {
        message = "Unknown error.";
      }
      this.toggleToast(message, true);
    }
    this.setState({ articleLoading: false });
  };

  getSources = async type => {
    this.setState({
      page: "sources",
      pageLoading: true,
      filter: type
    });
    const sourcesData = await this.fetch(
      `${this.state.api}/api/sources?type=${type}`
    );
    if (sourcesData.ok) {
      const sources = await sourcesData.json();
      this.setState({
        sources: sources.map(source => ({ ...source, loading: false }))
      });
    }
    this.setState({ pageLoading: false });
  };

  getArticles = async (sourceFilter = "", articlePage = 1) => {
    this.setState({
      page: "articles",
      pageLoading:
        this.state.sourceFilter !== sourceFilter ||
        this.state.page !== "articles",
      articleLoading: true,
      sourceFilter,
      articlePage
    });
    const articlesData = await this.fetch(
      `${
        this.state.api
      }/api/articles?source=${sourceFilter}&page=${articlePage}`
    );
    if (articlesData.ok) {
      const {
        articles,
        hasPrevious,
        hasNext,
        totalCount
      } = await articlesData.json();
      this.setState({
        articles: articles.map(article => ({ ...article, loading: false })),
        articlesPageInfo: {
          hasPrevious,
          hasNext,
          totalCount
        }
      });
    }
    this.setState({ pageLoading: false, articleLoading: false });
  };

  getAnalytics = async (articlePage = 1, analyticsPost = null) => {
    this.setState({
      page: "analytics",
      pageLoading:
        this.state.analyticsPost?.id !== analyticsPost?.id ||
        this.state.page !== "analytics",
      articleLoading: true,
      articlePage,
      analyticsPost
    });
    const [start, end] = this.state.analyticsRange;
    const startDate = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      23,
      59,
      59,
      999
    );
    const analyticsData = await this.fetch(
      `${this.state.api}/api/analytics?postId=${analyticsPost?.id ||
        ""}&page=${articlePage}&start=${startDate.toJSON()}&end=${endDate.toJSON()}`
    );
    if (analyticsData.ok) {
      const result = await analyticsData.json();
      this.setState({
        analytics: result
      });
    }
    this.setState({ pageLoading: false, articleLoading: false });
  };

  getSettings = async (initialize = false) => {
    clearTimeout(this.settingsTO);
    const settingsData = await this.fetch(`${this.state.api}/api/settings`);
    if (settingsData.ok) {
      const settings = await settingsData.json();
      settings.last_sources = settings.last_sources.map(source => ({
        ...source,
        loading: false
      }));
      const blog = settings.blogs.find(b => b.id == settings.blog_id) || null;
      if (initialize) {
        if (!this.app) {
          this.app =
            this.props.app ||
            createApp({
              apiKey: "7d819158f4ddf83338b830c80007e058",
              shopOrigin: settings.domain
            });
        }
        settings.initializing = false;
        settings.blog = blog;
        settings.hasError = !settings.medium_profile;
        if (typeof settings.newsletter !== "boolean") {
          settings.newsletterModal = true;
        }
        settings.showPayment = !settings.active;
        this.setState(settings);
      } else {
        this.setState({
          last_sync: settings.last_sync,
          active: settings.active || false,
          medium_import: settings.medium_import,
          trial_active: settings.trial_active || false,
          trial: settings.trial,
          last_sources: settings.last_sources,
          posts: settings.posts,
          post_ids: settings.post_ids,
          blogs: settings.blogs,
          monthlyPosts: settings.monthlyPosts || 0,
          sourcesCount: settings.sourcesCount || 0,
          blog,
          recentArticles: settings.recentArticles || [],
          currentReaders: settings.currentReaders || 0
        });
      }
    }
    this.settingsTO = setTimeout(() => this.getSettings(), 1 * 60 * 1000);
  };

  setNewsletter = async value => {
    const { newsletter, newsletterLoading } = this.state;
    if (newsletterLoading) return false;
    this.setState({ newsletterLoading: true });
    const newsletterData = await this.fetch(
      `${this.state.api}/api/newsletter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          newsletter: typeof value === "boolean" ? value : newsletter
        })
      }
    );
    if (newsletterData.ok) {
      const settings = await newsletterData.json();
      this.setState({
        ...settings,
        newsletterLoading: false,
        newsletterModal: false
      });
    } else {
      this.toggleToast(
        "We could not update your newsletter subscription status.",
        true
      );
      this.setState({ newsletterLoading: false });
    }
  };

  openImport = () => {
    this.uploader.current.click();
  };

  cancelImport = async () => {
    if (this.state.importLoading) return false;
    this.setState({ importLoading: true });
    const res = await this.fetch(`${this.state.api}/api/medium/import`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ cancel: true })
    });
    if (res.ok) {
      const update = await res.json();
      this.setState({
        importLoading: false,
        medium_import: update.medium_import
      });
      if (update.status !== "completed") {
        this.toggleToast(`Import cancelled successfully.`);
      }
    } else {
      this.setState({ importLoading: false });
    }
  };

  importPosts = async () => {
    if (this.state.importLoading) return false;
    const file = this.uploader.current.files[0];
    if (file) {
      this.setState({ importLoading: true });
      const form = new FormData();
      form.append("export", file);
      const res = await this.fetch(`${this.state.api}/api/medium/import`, {
        method: "POST",
        body: form
      });
      this.uploader.current.value = "";
      if (res.ok) {
        const update = await res.json();
        this.setState({
          importLoading: false,
          medium_import:
            update.status === "completed" ? null : update.medium_import
        });
        if (update.status === "created") {
          this.toggleToast(
            `Started synchronizing ${update.medium_import.total} Medium posts!`
          );
        } else if (update.status === "completed") {
          this.toggleToast(
            `We did not find any posts to import that did not already exist.`
          );
        }
      } else {
        try {
          const error = await res.json();
          this.toggleToast(error.message, true);
        } catch (e) {
          this.toggleToast("Unknown error, please retry.", true);
        }
        this.setState({ importLoading: false });
      }
    }
  };

  importPostsV2 = async () => {
    if (this.state.importLoading) return false;
    const { mediumImportRequest } = this.state;
    const { blog_id, file } = mediumImportRequest;
    if (blog_id && file) {
      this.setState({ importLoading: true });
      const path = `imports/uploads/${Date.now().toString(16)}-${file.name}`;
      try {
        await firebase
          .storage()
          .ref(path)
          .put(file);
      } catch (err) {
        this.toggleToast("We could not upload your ZIP archive.", true);
        this.setState({ importLoading: false });
        return false;
      }
      const res = await this.fetch(`${this.state.api}/api/medium/import`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          blog_id,
          path
        })
      });
      if (res.ok) {
        const update = await res.json();
        this.setState({
          importLoading: false,
          mediumImportRequest: null,
          medium_import:
            update.status === "completed" ? null : update.medium_import
        });
        if (update.status === "created") {
          this.toggleToast(
            `Started synchronizing ${update.medium_import.total} Medium posts!`
          );
        } else if (update.status === "completed") {
          this.toggleToast(
            `We did not find any posts to import that did not already exist.`
          );
        }
      } else {
        try {
          const error = await res.json();
          this.toggleToast(error.message, true);
        } catch (e) {
          this.toggleToast("Unknown error, please retry.", true);
        }
        this.setState({ importLoading: false });
      }
    }
  };

  createCharge = async (yearly = false) => {
    if (this.state.chargeLoading) return false;
    this.setState({ chargeLoading: true });
    const res = await this.fetch(
      `${this.state.api}/api/${yearly ? "yearly-" : ""}charge`,
      {
        method: "POST"
      }
    );
    if (res.ok) {
      const charge = await res.json();
      this.app.dispatch(
        Redirect.toRemote({
          url: charge.confirmation_url
        })
      );
    } else {
      this.setState({ chargeLoading: false });
    }
  };

  cancelSubscription = async () => {
    if (this.state.cancelLoading) return false;
    this.setState({ cancelLoading: true });
    const res = await this.fetch(`${this.state.api}/api/cancel-subscription`, {
      method: "POST"
    });
    if (res.ok) {
      const settings = await res.json();
      settings.cancelLoading = settings.cancelModal = false;
      this.setState(settings);
      this.toggleToast("Your subscription was cancelled successfully.");
    } else {
      try {
        const error = await res.json();
        this.toggleToast(error.message, true);
      } catch (e) {
        this.toggleToast("Unknown error, please retry.", true);
      }
      this.setState({ cancelLoading: false, cancelModal: false });
    }
  };

  componentDidMount = () => {
    this.app = this.context;
    this.fetch = authenticatedFetch(this.app);
    this.getSettings(true);
  };

  componentWillUnmount = () => {
    clearTimeout(this.settingsTO);
  };

  handleSubmit = async () => {
    if (this.hasError || this.state.settingsLoading) return false;
    this.setState({ settingsLoading: true });
    const res = await this.fetch(`${this.state.api}/api/settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        medium_profile: this.state.medium_profile,
        blog_id: this.state.blog_id,
        redirect: this.state.redirect
      })
    });
    if (res.ok) {
      const settings = await res.json();
      settings.blog =
        this.state.blogs.find(b => b.id == settings.blog_id) || null;
      this.setState(settings);
      this.toggleToast("Saved!");
    } else {
      try {
        const error = await res.json();
        this.toggleToast(error.message, true);
      } catch (e) {
        this.toggleToast("Unknown error, please retry.", true);
      }
    }
    this.setState({ settingsLoading: false });
  };

  handleChange = field => {
    return value => {
      const { fields } = this.state;
      if (fields[field] && typeof fields[field].validate === "function")
        fields[field].error = fields[field].validate(value);
      this.setState({
        [field]: value,
        fields,
        hasError: Object.keys(fields).findIndex(key => fields[key].error) > -1
      });
    };
  };

  pullPosts = async () => {
    if (this.state.pullLoading) return false;
    this.setState({ pullLoading: true });
    const res = await this.fetch(`${this.state.api}/api/pull`, {
      method: "POST"
    });
    if (res.ok) {
      const report = await res.json();
      this.toggleToast(`${report.count} new article(s) synced!`);
      this.setState({ last_sync: report.last_sync, posts: report.posts });
    } else {
      try {
        const error = await res.json();
        this.toggleToast(error.message, true);
      } catch (e) {
        this.toggleToast("Unknown error, please retry.", true);
      }
    }
    this.setState({ pullLoading: false });
  };

  setSync = async autosync => {
    if (this.state.syncLoading) return false;
    this.setState({ syncLoading: true });
    const res = await this.fetch(`${this.state.api}/api/settings/autosync`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ autosync })
    });
    if (res.ok) {
      const settings = await res.json();
      this.setState(settings);
    } else {
      try {
        const error = await res.json();
        this.toggleToast(error.message, true);
      } catch (e) {
        this.toggleToast("Unknown error, please retry.", true);
      }
    }
    this.setState({ syncLoading: false });
  };

  toggleToast = (content = "", error = false) => {
    this.setState({
      showToast: !this.state.showToast,
      toastContent: content,
      toastError: error
    });
  };
}

export default IndexV3;
