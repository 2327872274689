import React from "react";
import ReactDOM from "react-dom";
import { AppProvider } from "@shopify/polaris";
import { Provider } from "@shopify/app-bridge-react";
// import Index from "./index";
import IndexV3 from "./index-v3";
import qs from "query-string";
import firebase from "firebase/app";
import "firebase/storage";
import "@shopify/polaris/styles.css";
// import "./assets/style.css";

firebase.initializeApp({
  apiKey: "AIzaSyA29HEfopke_3jPepgW1UHyQCr8FGONdQ4",
  authDomain: "medium-feed.firebaseapp.com",
  databaseURL: "https://medium-feed.firebaseio.com",
  projectId: "medium-feed",
  storageBucket: "medium-feed.appspot.com",
  messagingSenderId: "1066636504710",
  appId: "1:1066636504710:web:45b15812255011241f6b07"
});

function MyApp() {
  const { shop: shopOrigin } = qs.parse(window.location.search);
  const config = { apiKey: API_KEY, shopOrigin };
  const theme = {
    colors: {
      topBar: {
        background: "#fff"
      }
    },
    logo: {
      width: 40,
      topBarSource:
        "https://firebasestorage.googleapis.com/v0/b/medium-feed.appspot.com/o/pugsync-logo.png?alt=media&token=eaea6b18-0fc8-485f-b8b1-e37990e6713c",
      accessibilityLabel: "PugSync"
    }
  };

  return (
    <AppProvider theme={theme}>
      <Provider config={config}>
        <IndexV3 />
      </Provider>
    </AppProvider>
  );
}

const root = document.createElement("div");
document.body.appendChild(root);
ReactDOM.render(<MyApp />, root);
